<template>
  <section>

    <div>
      <t-input ref="answerInput"
               :disabled="answerShown || flashcard?.answers === undefined || flashcard?.answers === null || flashcard.answers.length === 0"
               v-model="answerSubmitted"
               v-on:keyup.enter="submitAnswer"
               :placeholder="$t(flashcard?.answers === undefined || flashcard?.answers === null || flashcard.answers.length === 0 ? 'quiz.noCardSelected' : 'quiz.inputPlaceholder')"
               class="text-5xl px-5 py-3 placeholder-gray-300: text-center w-full">
      </t-input>
    </div>

    <br>

    <t-card v-if="answerShown && answerIsCorrect" class="bg-green-50 text-green-700 border-green-200 p-5">
      <div class="flex justify-around">
        <div class="w-4/6">
          <h2>✔️ {{$t('quiz.answerIsCorrect')}}</h2>
        </div>
        <div class="w-2/6">
          <t-button variant="success" class="self-center px-5 py-5 text-4xl" @click="goToNextFlashcard">{{ $t('quiz.continue') }}</t-button>
        </div>
      </div>
    </t-card>
    <t-card v-if="answerShown && !answerIsCorrect" class="bg-red-50 text-red-700 border-red-200 p-5">
      <div class="flex justify-around">
        <div class="w-4/6">
          <h2>❌ {{ $t('quiz.correctAnswerIs') }}: {{ flashcard.answers.join(' / ') }}</h2>
        </div>
        <div class="w-2/6">
          <t-button variant="error" class="self-center px-5 py-5 text-4xl" @click="goToNextFlashcard">{{ $t('quiz.continue') }}</t-button>
        </div>
      </div>
    </t-card>

  </section>
</template>

<script>
import { TInput, TButton, TCard } from '@variantjs/vue'
import { event } from 'vue-gtag'
export default {
  name: "Answer",
  props: {
    flashcard: {answers: []},
    answerShown: Boolean
  },
  components: {
    TInput,
    TButton,
    TCard
  },
  data: function () {
    return {
      answerSubmitted: '',
      answerIsCorrect: false
    }
  },
  mounted() {
    this.$refs.answerInput.$el.focus();
    window.addEventListener('keypress', this.doCommand);
  },
  unmounted() {
    window.removeEventListener('keypress', this.doCommand);
  },
  methods: {
    submitAnswer: function () {
      const answerSubmittedCleaned = this.answerSubmitted?.trim()?.toLowerCase();
      if (answerSubmittedCleaned === undefined || answerSubmittedCleaned === null || answerSubmittedCleaned === '') {
        return;
      }

      this.answerIsCorrect = this.flashcard.answers.includes(answerSubmittedCleaned);
      if (this.answerIsCorrect) {
        event('answerCorrect');
      } else {
        event('answerIncorrect');
      }
      this.$emit('showAnswer');
      this.$emit('updateScore', this.answerIsCorrect);
    },
    goToNextFlashcard: function () {
      this.answerSubmitted = '';
      this.answerIsCorrect = false;
      this.$emit('hideAnswer');
      this.$emit('goToNextFlashcard');
      this.$nextTick(() => {
        this.$refs.answerInput.$el.focus();
      });
    },
    doCommand(event) {
      if(this.answerShown && event.key === 'Enter') {
        this.goToNextFlashcard();
      }
    }
  }
}
</script>

<style scoped>

</style>
