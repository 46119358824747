<template>
  <div id="app">
    <Headers :settings="settings"></Headers>
    <router-view :settings="settings"/>
  </div>
</template>

<script>

import Headers from "./components/Headers";

export default {
  name: 'App',
  components: {
    Headers
  },
  data: function () {
    return {
      settings: {'audioEnabled': true}
    }
  }
}
</script>

<style>
</style>
