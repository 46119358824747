import { createI18n as _createI18n } from 'vue-i18n'
import en from './locales/en.json'
import fr from './locales/fr.json'

// const messageImports = import.meta.glob('./locales/*.json')
// console.log(messageImports)

export const SUPPORT_LOCALES = ['en', 'fr']

export function createI18n() {
    return _createI18n({
        legacy: false,
        globalInjection: true,
        locale: 'en',
        fallbackLocale: 'fr',
        messages: {
            en,
            fr
        }
    })
}
