<template>
  <h1 class="text-8xl text-center">{{ question === undefined || question === null ? 'ㅤ' : question }}</h1>
</template>

<script>
export default {
  name: "Question",
  props: {
    question: String
  }
}
</script>

<style scoped>

</style>
