import {createApp} from 'vue';
import App from './App.vue'
import VueGtag from "vue-gtag";
import {variantJS} from '@variantjs/vue'
import { createI18n } from './i18n'
import './index.css'
import router from './router'
import './registerServiceWorker'


const app = createApp(App);

app.use(VueGtag, {
    config: { id: "G-MQF2V5XGK3" }
});

const configuration = {
    TButton: {
        // The fixed classes will never change and will be merged with the `classes` value or the active variant
        fixedClasses: 'focus:outline-none focus:shadow-outline inline-flex items-center transition ease-in-out duration-150',
        // Classes used when any variant is active
        classes: 'text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 active:bg-blue-700 text-sm font-medium border border-transparent px-3 py-2 rounded-md',
        variants: {
            // A red variant of the button (applied when `<t-button variant="error" />`)
            error: {
                classes: 'text-white bg-red-600 hover:bg-red-500 focus:border-red-700 active:bg-red-700 text-sm font-medium border border-transparent px-3 py-2 rounded-md',
            },
            // A green variant of the button (applied when `<t-button variant="success" />`)
            success: {
                classes: 'text-white bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700 text-sm font-medium border border-transparent px-3 py-2 rounded-md',
            },
            // ...unlimited variants
        }
        // ...More settings
    },
    TDropdown: {
        fixedClasses: {
            button: 'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
            wrapper: 'inline-flex flex-col',
            dropdownWrapper: 'relative z-10',
            dropdown: 'origin-top-left absolute left-0 w-56 rounded shadow mt-1',
            enterClass: 'opacity-0 scale-95',
            enterActiveClass: 'transition transform ease-out duration-100',
            enterToClass: 'opacity-100 scale-100',
            leaveClass: 'opacity-100 scale-100',
            leaveActiveClass: 'transition transform ease-in duration-75',
            leaveToClass: 'opacity-0 scale-95'
        },
        classes: {
            button: 'bg-blue-500 hover:bg-blue-600',
            dropdown: 'bg-white'
        },
        variants: {
            danger: {
                classes: {
                    button: 'bg-red-500 hover:bg-red-600',
                    dropdown: 'bg-red-500'
                }
            }
        }
    },
    TModal: {
        fixedClasses: {
            overlay: 'z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
            wrapper: 'w-9/12 relative mx-auto z-50 px-3 py-12',
            modal: 'overflow-visible relative  rounded',
            body: 'p-3',
            header: 'border-b p-3 rounded-t',
            footer: ' p-3 rounded-b',
            close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50'
        },
        classes: {
            overlay: 'bg-black',
            modal: 'bg-white shadow',
            body: 'p-3',
            header: 'border-gray-100',
            footer: 'bg-gray-100',
            close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
            closeIcon: 'fill-current h-4 w-4',
            overlayEnterClass: 'opacity-0',
            overlayEnterActiveClass: 'transition ease-out duration-100',
            overlayEnterToClass: 'opacity-100',
            overlayLeaveClass: 'opacity-100',
            overlayLeaveActiveClass: 'transition ease-in duration-75',
            overlayLeaveToClass: 'opacity-0',
            enterClass: '',
            enterActiveClass: '',
            enterToClass: '',
            leaveClass: '',
            leaveActiveClass: '',
            leaveToClass: ''
        },
        variants: {
            alphabetTable: {
                classes: {
                    wrapper: 'w-9/12 relative mx-auto z-50 px-3 py-12',
                }
            }
        }
    }
};

app.use(variantJS, configuration);

const i18n = createI18n();
app.use(i18n);

app.use(router);

app.mount("#app");
