<template>
  <div class="flex flex-col">
    <h1 class="text-center text-red-600">🗻{{ $t('app.title') }}</h1>
    <br>
    <div class="flex flex-row justify-end space-x-4 w-9/12 self-center">
      <t-tag
          class="rounded-lg text-medium font-medium bg-yellow-50 text-gray-500 shadow-lg cursor-pointer"
          @click="$modal.show('alphabetTable')">
        <p class="p-3 text-center">{{ getSelectedCategories()}}</p>
      </t-tag>
      <t-modal name="alphabetTable" @before-hide="doBeforeHidingAlphabetTable" variant="alphabetTable"><kana-table :kana="{flashcards: flashcards}"></kana-table></t-modal>
      <t-tag
          class="rounded-lg text-medium font-medium bg-green-50 text-gray-500 shadow-lg cursor-pointer"
          @click="$modal.show('score')" >
        <p class="p-3 text-center">{{ $t('quiz.score') }}: {{ answersCorrect }} / {{ questionsTotal }}</p>
      </t-tag>
      <t-modal name="score">
        <div class="divide-y px-7">
          <div>
            <t-button @click="resetScore" variant="error">{{$t('quiz.resetScore')}}</t-button>
          </div>
          <br>
          <div>
            {{$t('quiz.history')}}:
            <br>
            <ul class="list-disc">
              <li v-for="fl in history.slice().reverse()" :key="fl.question">
                {{ fl.question }} ( {{ fl.answers.toString() }} )
              </li>
            </ul>
          </div>
        </div>

      </t-modal>
    </div>
    <br>

    <t-card class="rounded-xl shadow-2xl w-9/12 self-center bg-white">
      <br>
      <div @click="this.settings.audioEnabled && answerShown ? playAudio() : null"
           :class="this.settings.audioEnabled && answerShown ? 'cursor-pointer' : ''">
        <Question :question=flashcard?.question></Question>
      </div>
      <br><br>
      <Answer :flashcard=flashcard
              :answerShown="answerShown"
              @showAnswer="answerShown = true"
              @hideAnswer="answerShown = false"
              v-on:updateScore="updateScore"
              v-on:goToNextFlashcard="goToNextFlashcard">
      </Answer>
    </t-card>
  </div>
</template>

<script>
import Question from "./Question";
import Answer from "./Answer";
import {TCard, TTag, TModal, TButton} from '@variantjs/vue'
import {event} from 'vue-gtag'
import {kana} from '../flashcards/kana.js'
import KanaTable from "./alphabet-tables/KanaTable";

export default {
  name: 'Quiz',
  components: {KanaTable, Answer, Question, TCard, TTag, TModal, TButton},
  props: {
    settings: {'audioEnabled': true}
  },
  data: function () {
    return {
      answerShown: false,
      questionsTotal: 0,
      answersCorrect: 0,
      flashcards: [],
      flashcard: {question: String, answer: String},
      history: []
    }
  },
  mounted() {
    this.flashcards = kana;

    if (localStorage.questionsTotal) {
      this.questionsTotal = localStorage.questionsTotal;
    }
    if (localStorage.answersCorrect) {
      this.answersCorrect = localStorage.answersCorrect;
    }
    if (localStorage.history) {
      this.history = JSON.parse(localStorage.history);
    }
    if (localStorage.flashcards) {
      this.flashcards = JSON.parse(localStorage.flashcards);
    }

    this.flashcard = this.getNewRandomFlashcard();
  },
  watch: {
    questionsTotal(newQuestionsTotal) {
      localStorage.questionsTotal = newQuestionsTotal;
    },
    answersCorrect(newAnswersCorrect) {
      localStorage.answersCorrect = newAnswersCorrect;
    }
  },
  methods: {
    getNewRandomFlashcard: function () {
      event('getNewRandomFlashcard', {event_label: 'questionsTotal', value: this.questionsTotal});

      const flashcardsPool = this.flashcards.filter(flashcard => flashcard.selected);
      if (flashcardsPool.length === 0) {
        return null;
      }

      const randomId = Math.floor(Math.random() * flashcardsPool.length);
      let newFlashcard = flashcardsPool[randomId];
      const limit = 50;
      let i = 0;
      while (flashcardsPool.length > 1 && i < limit && this.shouldGetNewFlashcard(newFlashcard.question, flashcardsPool.length)) {
        newFlashcard = flashcardsPool[(randomId + 1) % flashcardsPool.length];
        i++;
      }

      return newFlashcard;
    },
    shouldGetNewFlashcard(question, flashcardsPoolLength) {
      if (this.flashcard?.question === question) {
        return true;
      }
      if (flashcardsPoolLength > 8 && this.history.slice(-5).find(flashcard => flashcard.question === question)) {
        return true;
      }
      return false;
    },
    updateScore: function (isAnswerCorrect) {
      this.history.push(this.flashcard);
      if (this.history.length > 10) {
        this.history.shift();
      }
      localStorage.history = JSON.stringify(this.history);

      if (this.settings.audioEnabled) {
        this.playAudio();
      }

      this.questionsTotal++;
      if (isAnswerCorrect) {
        this.answersCorrect++;
      }
      event('submitAnswer', {event_label: 'score', value: '' + this.answersCorrect + '/' + this.questionsTotal})
    },
    goToNextFlashcard: function () {
      event('goToNextFlashcard');
      this.flashcard = this.getNewRandomFlashcard();
    },
    resetScore: function () {
      this.$dialog.confirm({
        title: this.$t('quiz.resetScore'),
        text: null,
        icon: 'warning',
        okButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel')
      })
          .then(() => {
            this.answersCorrect = 0;
            this.questionsTotal = 0;
          }, () => {
          });
    },
    playAudio() {
      const audio = new Audio('/audio/jp/' + this.flashcard.category + '/' + this.flashcard.question + '.mp3');
      audio.playbackRate = 0.9;
      audio.play();
    },
    getSelectedCategories() {
      const categories = [];
      for (const flashcard of this.flashcards.filter(flashcard => flashcard.selected)) {
        if (!categories.includes(flashcard.category)) {
          categories.push(flashcard.category);
        }
      }
      if (categories.length === 0) {
        return this.$t('quiz.noCardSelected');
      }
      return categories.map(category => category[0].toUpperCase() + category.slice(1).toLowerCase()).join('+');
    },
    saveFlashcards: function () {
      localStorage.flashcards = JSON.stringify(this.flashcards);
    },
    doBeforeHidingAlphabetTable() {
      this.saveFlashcards();

      const flashcardsPool = this.flashcards.filter(flashcard => flashcard.selected);
      if (!flashcardsPool.some(flashcard => flashcard.question === this.flashcard?.question)) {
        this.flashcard = this.getNewRandomFlashcard();
      }
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
