<template>
  <div class="flex flex-col">
    <div class="flex flex-col self-center w-9/12">
      <p @click="$modal.show('settings')" class="cursor-pointer self-end">⚙️</p>
    </div>
    <t-modal name="settings"
             :header="$t('app.settings.headers')">

      {{ $t('app.settings.language') + ': '}}
      <t-select v-model="$i18n.locale"
                :options=$i18n.availableLocales
                class="self-end">
      </t-select>

      <br>

      {{ $t('app.settings.audioEnabled') + ': '}}
      <t-toggle v-model="settings.audioEnabled"/>

    </t-modal>
  </div>
</template>

<script>
import { TSelect, TModal, TToggle } from '@variantjs/vue'
export default {
  name: "Headers",
  components: {
    TSelect,
    TModal,
    TToggle
  },
  props: {
    settings: {audioEnabled: true}
  },
  data: function() {
    return {
      showModal: false
    }
  },
  mounted() {
    if (localStorage.locale) {
      this.$i18n.locale = localStorage.locale;
      document.documentElement.lang = this.$i18n.locale;
    } else {
      for (let i = 0; i < navigator.languages.length; i++){
        const locale = navigator.languages[i].split('-')[0];
        if (this.$i18n.availableLocales.includes(locale)) {
          document.documentElement.lang = locale;
          break;
        }
      }
    }

    if (localStorage.audioEnabled) {
      this.settings.audioEnabled = !(localStorage.audioEnabled === 'false');
      localStorage.audioEnabled = this.settings.audioEnabled;
    }
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale === oldLocale) {
        return
      }
      localStorage.locale = newLocale;
      document.documentElement.lang = this.$i18n.locale;
    },
    'settings.audioEnabled': function(newAudioEnabled) {
      localStorage.audioEnabled = newAudioEnabled;
    }
  }
}
</script>

<style scoped>

</style>
