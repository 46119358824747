export const kana = [
    {question: 'あ', answers: ['a'], category: 'hiragana', selected: true},
    {question: 'い', answers: ['i'], category: 'hiragana', selected: true},
    {question: 'う', answers: ['u'], category: 'hiragana', selected: true},
    {question: 'え', answers: ['e'], category: 'hiragana', selected: true},
    {question: 'お', answers: ['o'], category: 'hiragana', selected: true},

    {question: 'か', answers: ['ka'], category: 'hiragana', selected: true},
    {question: 'き', answers: ['ki'], category: 'hiragana', selected: true},
    {question: 'く', answers: ['ku'], category: 'hiragana', selected: true},
    {question: 'け', answers: ['ke'], category: 'hiragana', selected: true},
    {question: 'こ', answers: ['ko'], category: 'hiragana', selected: true},

    {question: 'さ', answers: ['sa'], category: 'hiragana', selected: true},
    {question: 'し', answers: ['shi'], category: 'hiragana', selected: true},
    {question: 'す', answers: ['su'], category: 'hiragana', selected: true},
    {question: 'せ', answers: ['se'], category: 'hiragana', selected: true},
    {question: 'そ', answers: ['so'], category: 'hiragana', selected: true},

    {question: 'た', answers: ['ta'], category: 'hiragana', selected: true},
    {question: 'ち', answers: ['chi'], category: 'hiragana', selected: true},
    {question: 'つ', answers: ['tsu'], category: 'hiragana', selected: true},
    {question: 'て', answers: ['te'], category: 'hiragana', selected: true},
    {question: 'と', answers: ['to'], category: 'hiragana', selected: true},

    {question: 'な', answers: ['na'], category: 'hiragana', selected: true},
    {question: 'に', answers: ['ni'], category: 'hiragana', selected: true},
    {question: 'ぬ', answers: ['nu'], category: 'hiragana', selected: true},
    {question: 'ね', answers: ['ne'], category: 'hiragana', selected: true},
    {question: 'の', answers: ['no'], category: 'hiragana', selected: true},

    {question: 'は', answers: ['ha'], category: 'hiragana', selected: true},
    {question: 'ひ', answers: ['hi'], category: 'hiragana', selected: true},
    {question: 'ふ', answers: ['fu'], category: 'hiragana', selected: true},
    {question: 'へ', answers: ['he'], category: 'hiragana', selected: true},
    {question: 'ほ', answers: ['ho'], category: 'hiragana', selected: true},

    {question: 'ま', answers: ['ma'], category: 'hiragana', selected: true},
    {question: 'み', answers: ['mi'], category: 'hiragana', selected: true},
    {question: 'む', answers: ['mu'], category: 'hiragana', selected: true},
    {question: 'め', answers: ['me'], category: 'hiragana', selected: true},
    {question: 'も', answers: ['mo'], category: 'hiragana', selected: true},

    {question: 'や', answers: ['ya'], category: 'hiragana', selected: true},
    {question: 'ゆ', answers: ['yu'], category: 'hiragana', selected: true},
    {question: 'よ', answers: ['yo'], category: 'hiragana', selected: true},

    {question: 'ら', answers: ['ra'], category: 'hiragana', selected: true},
    {question: 'り', answers: ['ri'], category: 'hiragana', selected: true},
    {question: 'る', answers: ['ru'], category: 'hiragana', selected: true},
    {question: 'れ', answers: ['re'], category: 'hiragana', selected: true},
    {question: 'ろ', answers: ['ro'], category: 'hiragana', selected: true},

    {question: 'わ', answers: ['wa'], category: 'hiragana', selected: true},
    {question: 'を', answers: ['wo'], category: 'hiragana', selected: true},

    {question: 'ん', answers: ['n'], category: 'hiragana', selected: true},


    {question: 'が', answers: ['ga'], category: 'hiragana', selected: false},
    {question: 'ぎ', answers: ['gi'], category: 'hiragana', selected: false},
    {question: 'ぐ', answers: ['gu'], category: 'hiragana', selected: false},
    {question: 'げ', answers: ['ge'], category: 'hiragana', selected: false},
    {question: 'ご', answers: ['go'], category: 'hiragana', selected: false},
    {question: 'ざ', answers: ['za'], category: 'hiragana', selected: false},
    {question: 'じ', answers: ['ji'], category: 'hiragana', selected: false},
    {question: 'ず', answers: ['zu'], category: 'hiragana', selected: false},
    {question: 'ぜ', answers: ['ze'], category: 'hiragana', selected: false},
    {question: 'ぞ', answers: ['zo'], category: 'hiragana', selected: false},


    {question: 'だ', answers: ['da'], category: 'hiragana', selected: false},
    {question: 'ぢ', answers: ['di', 'ji'], category: 'hiragana', selected: false},
    {question: 'づ', answers: ['zu', 'dzu'], category: 'hiragana', selected: false},
    {question: 'で', answers: ['de'], category: 'hiragana', selected: false},
    {question: 'ど', answers: ['do'], category: 'hiragana', selected: false},

    {question: 'ば', answers: ['ba'], category: 'hiragana', selected: false},
    {question: 'び', answers: ['bi'], category: 'hiragana', selected: false},
    {question: 'ぶ', answers: ['bu'], category: 'hiragana', selected: false},
    {question: 'べ', answers: ['be'], category: 'hiragana', selected: false},
    {question: 'ぼ', answers: ['bo'], category: 'hiragana', selected: false},

    {question: 'ぱ', answers: ['pa'], category: 'hiragana', selected: false},
    {question: 'ぴ', answers: ['pi'], category: 'hiragana', selected: false},
    {question: 'ぷ', answers: ['pu'], category: 'hiragana', selected: false},
    {question: 'ぺ', answers: ['pe'], category: 'hiragana', selected: false},
    {question: 'ぽ', answers: ['po'], category: 'hiragana', selected: false},

    {question: 'きゃ', answers: ['kya'], category: 'hiragana', selected: false},
    {question: 'きゅ', answers: ['kyu'], category: 'hiragana', selected: false},
    {question: 'きょ', answers: ['kyo'], category: 'hiragana', selected: false},
    {question: 'しゃ', answers: ['sha'], category: 'hiragana', selected: false},
    {question: 'しゅ', answers: ['shu'], category: 'hiragana', selected: false},
    {question: 'しょ', answers: ['sho'], category: 'hiragana', selected: false},
    {question: 'ちゃ', answers: ['cha'], category: 'hiragana', selected: false},
    {question: 'ちゅ', answers: ['chu'], category: 'hiragana', selected: false},
    {question: 'ちょ', answers: ['cho'], category: 'hiragana', selected: false},
    {question: 'にゃ', answers: ['nya'], category: 'hiragana', selected: false},
    {question: 'にゅ', answers: ['nyu'], category: 'hiragana', selected: false},
    {question: 'にょ', answers: ['nyo'], category: 'hiragana', selected: false},
    {question: 'ひゃ', answers: ['hya'], category: 'hiragana', selected: false},
    {question: 'ひゅ', answers: ['hyu'], category: 'hiragana', selected: false},
    {question: 'ひょ', answers: ['hyo'], category: 'hiragana', selected: false},
    {question: 'みゃ', answers: ['mya'], category: 'hiragana', selected: false},
    {question: 'みゅ', answers: ['myu'], category: 'hiragana', selected: false},
    {question: 'みょ', answers: ['myo'], category: 'hiragana', selected: false},
    {question: 'りゃ', answers: ['rya'], category: 'hiragana', selected: false},
    {question: 'りゅ', answers: ['ryu'], category: 'hiragana', selected: false},
    {question: 'りょ', answers: ['ryo'], category: 'hiragana', selected: false},

    {question: 'ぎゃ', answers: ['gya'], category: 'hiragana', selected: false},
    {question: 'ぎゅ', answers: ['gyu'], category: 'hiragana', selected: false},
    {question: 'ぎょ', answers: ['gyo'], category: 'hiragana', selected: false},
    {question: 'じゃ', answers: ['ja', 'jya'], category: 'hiragana', selected: false},
    {question: 'じゅ', answers: ['ju', 'jyu'], category: 'hiragana', selected: false},
    {question: 'じょ', answers: ['jo', 'jyo'], category: 'hiragana', selected: false},
    {question: 'びゃ', answers: ['bya'], category: 'hiragana', selected: false},
    {question: 'びゅ', answers: ['byu'], category: 'hiragana', selected: false},
    {question: 'びょ', answers: ['byo'], category: 'hiragana', selected: false},
    {question: 'ぴゃ', answers: ['pya'], category: 'hiragana', selected: false},
    {question: 'ぴゅ', answers: ['pyu'], category: 'hiragana', selected: false},
    {question: 'ぴょ', answers: ['pyo'], category: 'hiragana', selected: false},

    {question: 'ぢゃ', answers: ['dya', 'ja', 'jya'], category: 'hiragana', selected: false},
    {question: 'ぢゅ', answers: ['dyu', 'ju', 'jyu'], category: 'hiragana', selected: false},
    {question: 'ぢょ', answers: ['dyo', 'jo', 'jyo'], category: 'hiragana', selected: false},

    {question: 'ア', answers: ['a'], category: 'katakana', selected: false},
    {question: 'イ', answers: ['i'], category: 'katakana', selected: false},
    {question: 'ウ', answers: ['u'], category: 'katakana', selected: false},
    {question: 'エ', answers: ['e'], category: 'katakana', selected: false},
    {question: 'オ', answers: ['o'], category: 'katakana', selected: false},
    {question: 'カ', answers: ['ka'], category: 'katakana', selected: false},
    {question: 'キ', answers: ['ki'], category: 'katakana', selected: false},
    {question: 'ク', answers: ['ku'], category: 'katakana', selected: false},
    {question: 'ケ', answers: ['ke'], category: 'katakana', selected: false},
    {question: 'コ', answers: ['ko'], category: 'katakana', selected: false},
    {question: 'サ', answers: ['sa'], category: 'katakana', selected: false},
    {question: 'シ', answers: ['shi'], category: 'katakana', selected: false},
    {question: 'ス', answers: ['su'], category: 'katakana', selected: false},
    {question: 'セ', answers: ['se'], category: 'katakana', selected: false},
    {question: 'ソ', answers: ['so'], category: 'katakana', selected: false},
    {question: 'タ', answers: ['ta'], category: 'katakana', selected: false},
    {question: 'チ', answers: ['chi'], category: 'katakana', selected: false},
    {question: 'ツ', answers: ['tsu'], category: 'katakana', selected: false},
    {question: 'テ', answers: ['te'], category: 'katakana', selected: false},
    {question: 'ト', answers: ['to'], category: 'katakana', selected: false},
    {question: 'ナ', answers: ['na'], category: 'katakana', selected: false},
    {question: 'ニ', answers: ['ni'], category: 'katakana', selected: false},
    {question: 'ヌ', answers: ['nu'], category: 'katakana', selected: false},
    {question: 'ネ', answers: ['ne'], category: 'katakana', selected: false},
    {question: 'ノ', answers: ['no'], category: 'katakana', selected: false},
    {question: 'ハ', answers: ['ha'], category: 'katakana', selected: false},
    {question: 'ヒ', answers: ['hi'], category: 'katakana', selected: false},
    {question: 'フ', answers: ['fu'], category: 'katakana', selected: false},
    {question: 'ヘ', answers: ['he'], category: 'katakana', selected: false},
    {question: 'ホ', answers: ['ho'], category: 'katakana', selected: false},
    {question: 'マ', answers: ['ma'], category: 'katakana', selected: false},
    {question: 'ミ', answers: ['mi'], category: 'katakana', selected: false},
    {question: 'ム', answers: ['mu'], category: 'katakana', selected: false},
    {question: 'メ', answers: ['me'], category: 'katakana', selected: false},
    {question: 'モ', answers: ['mo'], category: 'katakana', selected: false},
    {question: 'ヤ', answers: ['ya'], category: 'katakana', selected: false},
    {question: 'ユ', answers: ['yu'], category: 'katakana', selected: false},
    {question: 'ヨ', answers: ['yo'], category: 'katakana', selected: false},
    {question: 'ラ', answers: ['ra'], category: 'katakana', selected: false},
    {question: 'リ', answers: ['ri'], category: 'katakana', selected: false},
    {question: 'ル', answers: ['ru'], category: 'katakana', selected: false},
    {question: 'レ', answers: ['re'], category: 'katakana', selected: false},
    {question: 'ロ', answers: ['ro'], category: 'katakana', selected: false},
    {question: 'ワ', answers: ['wa'], category: 'katakana', selected: false},
    {question: 'ヲ', answers: ['wo'], category: 'katakana', selected: false},
    {question: 'ン', answers: ['n'], category: 'katakana', selected: false},

    {question: 'ガ', answers: ['ga'], category: 'katakana', selected: false},
    {question: 'ギ', answers: ['gi'], category: 'katakana', selected: false},
    {question: 'グ', answers: ['gu'], category: 'katakana', selected: false},
    {question: 'ゲ', answers: ['ge'], category: 'katakana', selected: false},
    {question: 'ゴ', answers: ['go'], category: 'katakana', selected: false},
    {question: 'ザ', answers: ['za'], category: 'katakana', selected: false},
    {question: 'ジ', answers: ['ji'], category: 'katakana', selected: false},
    {question: 'ズ', answers: ['zu'], category: 'katakana', selected: false},
    {question: 'ゼ', answers: ['ze'], category: 'katakana', selected: false},
    {question: 'ゾ', answers: ['zo'], category: 'katakana', selected: false},
    {question: 'ダ', answers: ['da'], category: 'katakana', selected: false},
    {question: 'ヂ', answers: ['di', 'ji'], category: 'katakana', selected: false},
    {question: 'ヅ', answers: ['zu', 'dzu'], category: 'katakana', selected: false},
    {question: 'デ', answers: ['de'], category: 'katakana', selected: false},
    {question: 'ド', answers: ['do'], category: 'katakana', selected: false},
    {question: 'バ', answers: ['ba'], category: 'katakana', selected: false},
    {question: 'ビ', answers: ['bi'], category: 'katakana', selected: false},
    {question: 'ブ', answers: ['bu'], category: 'katakana', selected: false},
    {question: 'ベ', answers: ['be'], category: 'katakana', selected: false},
    {question: 'ボ', answers: ['bo'], category: 'katakana', selected: false},
    {question: 'パ', answers: ['pa'], category: 'katakana', selected: false},
    {question: 'ピ', answers: ['pi'], category: 'katakana', selected: false},
    {question: 'プ', answers: ['pu'], category: 'katakana', selected: false},
    {question: 'ペ', answers: ['pe'], category: 'katakana', selected: false},
    {question: 'ポ', answers: ['po'], category: 'katakana', selected: false},

    {question: 'キャ', answers: ['kya'], category: 'katakana', selected: false},
    {question: 'キュ', answers: ['kyu'], category: 'katakana', selected: false},
    {question: 'キョ', answers: ['kyo'], category: 'katakana', selected: false},
    {question: 'シャ', answers: ['sha'], category: 'katakana', selected: false},
    {question: 'シュ', answers: ['shu'], category: 'katakana', selected: false},
    {question: 'ショ', answers: ['sho'], category: 'katakana', selected: false},
    {question: 'チャ', answers: ['cha'], category: 'katakana', selected: false},
    {question: 'チュ', answers: ['chu'], category: 'katakana', selected: false},
    {question: 'チョ', answers: ['cho'], category: 'katakana', selected: false},
    {question: 'ニャ', answers: ['nya'], category: 'katakana', selected: false},
    {question: 'ニュ', answers: ['nyu'], category: 'katakana', selected: false},
    {question: 'ニョ', answers: ['nyo'], category: 'katakana', selected: false},
    {question: 'ヒャ', answers: ['hya'], category: 'katakana', selected: false},
    {question: 'ヒュ', answers: ['hyu'], category: 'katakana', selected: false},
    {question: 'ヒョ', answers: ['hyo'], category: 'katakana', selected: false},
    {question: 'ミャ', answers: ['mya'], category: 'katakana', selected: false},
    {question: 'ミュ', answers: ['myu'], category: 'katakana', selected: false},
    {question: 'ミョ', answers: ['myo'], category: 'katakana', selected: false},
    {question: 'リャ', answers: ['rya'], category: 'katakana', selected: false},
    {question: 'リュ', answers: ['ryu'], category: 'katakana', selected: false},
    {question: 'リョ', answers: ['ryo'], category: 'katakana', selected: false},

    {question: 'ギャ', answers: ['gya'], category: 'katakana', selected: false},
    {question: 'ギュ', answers: ['gyu'], category: 'katakana', selected: false},
    {question: 'ギョ', answers: ['gyo'], category: 'katakana', selected: false},
    {question: 'ジャ', answers: ['ja', 'jya'], category: 'katakana', selected: false},
    {question: 'ジュ', answers: ['ju', 'jyu'], category: 'katakana', selected: false},
    {question: 'ジョ', answers: ['jo', 'jyo'], category: 'katakana', selected: false},
    {question: 'ビャ', answers: ['bya'], category: 'katakana', selected: false},
    {question: 'ビュ', answers: ['byu'], category: 'katakana', selected: false},
    {question: 'ビョ', answers: ['byo'], category: 'katakana', selected: false},
    {question: 'ピャ', answers: ['pya'], category: 'katakana', selected: false},
    {question: 'ピュ', answers: ['pyu'], category: 'katakana', selected: false},
    {question: 'ピョ', answers: ['pyo'], category: 'katakana', selected: false},

    {question: 'ヂャ', answers: ['dya', 'ja', 'jya'], category: 'katakana', selected: false},
    {question: 'ヂュ', answers: ['dyu', 'ju', 'jyu'], category: 'katakana', selected: false},
    {question: 'ヂョ', answers: ['dyo', 'jo', 'jyo'], category: 'katakana', selected: false},
]
