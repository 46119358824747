<template>
  <div>
    <div class="bg-white">
      <nav class="flex flex-col sm:flex-row">
        <button :class="'text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none' + (currentTab === 'katakana' ? '' : 'text-blue-500 border-b-2 font-medium border-blue-500')"
                @click="currentTab = 'hiragana'">
          Hiragana
        </button>
        <button :class="'text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none' + (currentTab !== 'katakana' ? '' : 'text-blue-500 border-b-2 font-medium border-blue-500')"
                @click="currentTab = 'katakana'">
          Katakana
        </button>
      </nav>
    </div>

    <div v-if="currentTab === 'hiragana'">
      <t-checkbox name="options" v-model="checkboxes.cb1_11.checked" @change="this.toggleGroup(checkboxes.cb1_11)"/> {{$t('kana.basics')}}
      <table class="table-auto w-full">
        <tbody>
        <tr>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb1.checked" @change="this.toggleGroup(checkboxes.cb1)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb2.checked" @change="this.toggleGroup(checkboxes.cb2)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb3.checked" @change="this.toggleGroup(checkboxes.cb3)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb4.checked" @change="this.toggleGroup(checkboxes.cb4)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb5.checked" @change="this.toggleGroup(checkboxes.cb5)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb6.checked" @change="this.toggleGroup(checkboxes.cb6)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb7.checked" @change="this.toggleGroup(checkboxes.cb7)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb8.checked" @change="this.toggleGroup(checkboxes.cb8)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb9.checked" @change="this.toggleGroup(checkboxes.cb9)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb10.checked" @change="this.toggleGroup(checkboxes.cb10)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb11.checked" @change="this.toggleGroup(checkboxes.cb11)"/></td>
        </tr>
        <tr>
          <td><div :class="getClass('あ')" @click="toggleSelected('あ')"><p class="font-black">あ</p>a</div></td>
          <td><div :class="getClass('か')" @click="toggleSelected('か')"><p class="font-black">か</p>ka</div></td>
          <td><div :class="getClass('さ')" @click="toggleSelected('さ')"><p class="font-black">さ</p>sa</div></td>
          <td><div :class="getClass('た')" @click="toggleSelected('た')"><p class="font-black">た</p>ta</div></td>
          <td><div :class="getClass('な')" @click="toggleSelected('な')"><p class="font-black">な</p>na</div></td>
          <td><div :class="getClass('は')" @click="toggleSelected('は')"><p class="font-black">は</p>ha</div></td>
          <td><div :class="getClass('ま')" @click="toggleSelected('ま')"><p class="font-black">ま</p>ma</div></td>
          <td><div :class="getClass('や')" @click="toggleSelected('や')"><p class="font-black">や</p>ya</div></td>
          <td><div :class="getClass('ら')" @click="toggleSelected('ら')"><p class="font-black">ら</p>ra</div></td>
          <td><div :class="getClass('わ')" @click="toggleSelected('わ')"><p class="font-black">わ</p>wa</div></td>
          <td><div :class="getClass('ん')" @click="toggleSelected('ん')"><p class="font-black">ん</p>n</div></td>
        </tr>
        <tr>
          <td><div :class="getClass('い')" @click="toggleSelected('い')"><p class="font-black">い</p>i</div></td>
          <td><div :class="getClass('き')" @click="toggleSelected('き')"><p class="font-black">き</p>ki</div></td>
          <td><div :class="getClass('し')" @click="toggleSelected('し')"><p class="font-black">し</p>shi</div></td>
          <td><div :class="getClass('ち')" @click="toggleSelected('ち')"><p class="font-black">ち</p>chi</div></td>
          <td><div :class="getClass('に')" @click="toggleSelected('に')"><p class="font-black">に</p>ni</div></td>
          <td><div :class="getClass('ひ')" @click="toggleSelected('ひ')"><p class="font-black">ひ</p>hi</div></td>
          <td><div :class="getClass('み')" @click="toggleSelected('み')"><p class="font-black">み</p>mi</div></td>
          <td></td>
          <td><div :class="getClass('り')" @click="toggleSelected('り')"><p class="font-black">り</p>ri</div></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><div :class="getClass('う')" @click="toggleSelected('う')"><p class="font-black">う</p>u</div></td>
          <td><div :class="getClass('く')" @click="toggleSelected('く')"><p class="font-black">く</p>ku</div></td>
          <td><div :class="getClass('す')" @click="toggleSelected('す')"><p class="font-black">す</p>su</div></td>
          <td><div :class="getClass('つ')" @click="toggleSelected('つ')"><p class="font-black">つ</p>tsu</div></td>
          <td><div :class="getClass('ぬ')" @click="toggleSelected('ぬ')"><p class="font-black">ぬ</p>nu</div></td>
          <td><div :class="getClass('ふ')" @click="toggleSelected('ふ')"><p class="font-black">ふ</p>fu</div></td>
          <td><div :class="getClass('む')" @click="toggleSelected('む')"><p class="font-black">む</p>mu</div></td>
          <td><div :class="getClass('ゆ')" @click="toggleSelected('ゆ')"><p class="font-black">ゆ</p>yu</div></td>
          <td><div :class="getClass('る')" @click="toggleSelected('る')"><p class="font-black">る</p>ru</div></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><div :class="getClass('え')" @click="toggleSelected('え')"><p class="font-black">え</p>e</div></td>
          <td><div :class="getClass('け')" @click="toggleSelected('け')"><p class="font-black">け</p>ke</div></td>
          <td><div :class="getClass('せ')" @click="toggleSelected('せ')"><p class="font-black">せ</p>se</div></td>
          <td><div :class="getClass('て')" @click="toggleSelected('て')"><p class="font-black">て</p>te</div></td>
          <td><div :class="getClass('ね')" @click="toggleSelected('ね')"><p class="font-black">ね</p>ne</div></td>
          <td><div :class="getClass('へ')" @click="toggleSelected('へ')"><p class="font-black">へ</p>he</div></td>
          <td><div :class="getClass('め')" @click="toggleSelected('め')"><p class="font-black">め</p>me</div></td>
          <td></td>
          <td><div :class="getClass('れ')" @click="toggleSelected('れ')"><p class="font-black">れ</p>re</div></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><div :class="getClass('お')" @click="toggleSelected('お')"><p class="font-black">お</p>o</div></td>
          <td><div :class="getClass('こ')" @click="toggleSelected('こ')"><p class="font-black">こ</p>ko</div></td>
          <td><div :class="getClass('そ')" @click="toggleSelected('そ')"><p class="font-black">そ</p>so</div></td>
          <td><div :class="getClass('と')" @click="toggleSelected('と')"><p class="font-black">と</p>to</div></td>
          <td><div :class="getClass('の')" @click="toggleSelected('の')"><p class="font-black">の</p>no</div></td>
          <td><div :class="getClass('ほ')" @click="toggleSelected('ほ')"><p class="font-black">ほ</p>ho</div></td>
          <td><div :class="getClass('も')" @click="toggleSelected('も')"><p class="font-black">も</p>mo</div></td>
          <td><div :class="getClass('よ')" @click="toggleSelected('よ')"><p class="font-black">よ</p>yo</div></td>
          <td><div :class="getClass('ろ')" @click="toggleSelected('ろ')"><p class="font-black">ろ</p>ro</div></td>
          <td><div :class="getClass('を')" @click="toggleSelected('を')"><p class="font-black">を</p>wo</div></td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <br><br>

      <div class="flex flex-row justify-between space-x-4">
        <div class="w-7/12 bg-blue-50 rounded-lg shadow-xl">
          <t-checkbox name="options" v-model="checkboxes.cb12_23.checked" @change="this.toggleGroup(checkboxes.cb12_23)"/> {{$t('kana.digraphs')}}
          <table class="table-auto w-full">
            <tr>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb12.checked" @change="this.toggleGroup(checkboxes.cb12)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb13.checked" @change="this.toggleGroup(checkboxes.cb13)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb14.checked" @change="this.toggleGroup(checkboxes.cb14)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb15.checked" @change="this.toggleGroup(checkboxes.cb15)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb16.checked" @change="this.toggleGroup(checkboxes.cb16)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb17.checked" @change="this.toggleGroup(checkboxes.cb17)"/></td>
            </tr>
            <tr>
              <td><div :class="getClass('ぢゃ')" @click="toggleSelected('ぢゃ')"><p class="font-black">ぢゃ</p>dya/ja/jya</div></td>
              <td><div :class="getClass('ちゃ')" @click="toggleSelected('ちゃ')"><p class="font-black">ちゃ</p>cha</div></td>
              <td><div :class="getClass('じゃ')" @click="toggleSelected('じゃ')"><p class="font-black">じゃ</p>ja/jya</div></td>
              <td><div :class="getClass('しゃ')" @click="toggleSelected('しゃ')"><p class="font-black">しゃ</p>sha</div></td>
              <td><div :class="getClass('ぎゃ')" @click="toggleSelected('ぎゃ')"><p class="font-black">ぎゃ</p>gya</div></td>
              <td><div :class="getClass('きゃ')" @click="toggleSelected('きゃ')"><p class="font-black">きゃ</p>kya</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ぢゅ')" @click="toggleSelected('ぢゅ')"><p class="font-black">ぢゅ</p>dyu/ju/jyu</div></td>
              <td><div :class="getClass('ちゅ')" @click="toggleSelected('ちゅ')"><p class="font-black">ちゅ</p>chu</div></td>
              <td><div :class="getClass('じゅ')" @click="toggleSelected('じゅ')"><p class="font-black">じゅ</p>ju/jyu</div></td>
              <td><div :class="getClass('しゅ')" @click="toggleSelected('しゅ')"><p class="font-black">しゅ</p>shu</div></td>
              <td><div :class="getClass('ぎゅ')" @click="toggleSelected('ぎゅ')"><p class="font-black">ぎゅ</p>gyu</div></td>
              <td><div :class="getClass('きゅ')" @click="toggleSelected('きゅ')"><p class="font-black">きゅ</p>kyu</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ぢょ')" @click="toggleSelected('ぢょ')"><p class="font-black">ぢょ</p>dyo/jo/jyo</div></td>
              <td><div :class="getClass('ちょ')" @click="toggleSelected('ちょ')"><p class="font-black">ちょ</p>cho</div></td>
              <td><div :class="getClass('じょ')" @click="toggleSelected('じょ')"><p class="font-black">じょ</p>jo/jyo</div></td>
              <td><div :class="getClass('しょ')" @click="toggleSelected('しょ')"><p class="font-black">しょ</p>sho</div></td>
              <td><div :class="getClass('ぎょ')" @click="toggleSelected('ぎょ')"><p class="font-black">ぎょ</p>gyo</div></td>
              <td><div :class="getClass('きょ')" @click="toggleSelected('きょ')"><p class="font-black">きょ</p>kyo</div></td>
            </tr>
          </table>
          <br>
          <table class="table-auto w-full">
            <tr>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb18.checked" @change="this.toggleGroup(checkboxes.cb18)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb19.checked" @change="this.toggleGroup(checkboxes.cb19)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb20.checked" @change="this.toggleGroup(checkboxes.cb20)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb21.checked" @change="this.toggleGroup(checkboxes.cb21)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb22.checked" @change="this.toggleGroup(checkboxes.cb22)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb23.checked" @change="this.toggleGroup(checkboxes.cb23)"/></td>
            </tr>
            <tr>
              <td><div :class="getClass('りゃ')" @click="toggleSelected('りゃ')"><p class="font-black">りゃ</p>rya</div></td>
              <td><div :class="getClass('みゃ')" @click="toggleSelected('みゃ')"><p class="font-black">みゃ</p>mya</div></td>
              <td><div :class="getClass('ぴゃ')" @click="toggleSelected('ぴゃ')"><p class="font-black">ぴゃ</p>pya</div></td>
              <td><div :class="getClass('びゃ')" @click="toggleSelected('びゃ')"><p class="font-black">びゃ</p>bya</div></td>
              <td><div :class="getClass('ひゃ')" @click="toggleSelected('ひゃ')"><p class="font-black">ひゃ</p>hya</div></td>
              <td><div :class="getClass('にゃ')" @click="toggleSelected('にゃ')"><p class="font-black">にゃ</p>nya</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('りゅ')" @click="toggleSelected('りゅ')"><p class="font-black">りゅ</p>ryu</div></td>
              <td><div :class="getClass('みゅ')" @click="toggleSelected('みゅ')"><p class="font-black">みゅ</p>myu</div></td>
              <td><div :class="getClass('ぴゅ')" @click="toggleSelected('ぴゅ')"><p class="font-black">ぴゅ</p>pyu</div></td>
              <td><div :class="getClass('びゅ')" @click="toggleSelected('びゅ')"><p class="font-black">びゅ</p>byu</div></td>
              <td><div :class="getClass('ひゅ')" @click="toggleSelected('ひゅ')"><p class="font-black">ひゅ</p>hyu</div></td>
              <td><div :class="getClass('にゅ')" @click="toggleSelected('にゅ')"><p class="font-black">にゅ</p>nyu</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('りょ')" @click="toggleSelected('りょ')"><p class="font-black">りょ</p>ryo</div></td>
              <td><div :class="getClass('みょ')" @click="toggleSelected('みょ')"><p class="font-black">みょ</p>myo</div></td>
              <td><div :class="getClass('ぴょ')" @click="toggleSelected('ぴょ')"><p class="font-black">ぴょ</p>pyo</div></td>
              <td><div :class="getClass('びょ')" @click="toggleSelected('びょ')"><p class="font-black">びょ</p>byo</div></td>
              <td><div :class="getClass('ひょ')" @click="toggleSelected('ひょ')"><p class="font-black">ひょ</p>hyo</div></td>
              <td><div :class="getClass('にょ')" @click="toggleSelected('にょ')"><p class="font-black">にょ</p>nyo</div></td>
            </tr>
          </table>
        </div>
        <div class="w-6/12">
          <t-checkbox name="options" v-model="checkboxes.cb24_28.checked" @change="this.toggleGroup(checkboxes.cb24_28)"/> {{$t('kana.diacritics')}}
          <table class="table-auto w-full">
            <tr>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb24.checked" @change="this.toggleGroup(checkboxes.cb24)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb25.checked" @change="this.toggleGroup(checkboxes.cb25)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb26.checked" @change="this.toggleGroup(checkboxes.cb26)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb27.checked" @change="this.toggleGroup(checkboxes.cb27)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb28.checked" @change="this.toggleGroup(checkboxes.cb28)"/></td>
            </tr>
            <tr>
              <td><div :class="getClass('ば')" @click="toggleSelected('ば')"><p class="font-black">ば</p>ba</div></td>
              <td><div :class="getClass('ぱ')" @click="toggleSelected('ぱ')"><p class="font-black">ぱ</p>pa</div></td>
              <td><div :class="getClass('だ')" @click="toggleSelected('だ')"><p class="font-black">だ</p>da</div></td>
              <td><div :class="getClass('ざ')" @click="toggleSelected('ざ')"><p class="font-black">ざ</p>za</div></td>
              <td><div :class="getClass('が')" @click="toggleSelected('が')"><p class="font-black">が</p>ga</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('び')" @click="toggleSelected('び')"><p class="font-black">び</p>bi</div></td>
              <td><div :class="getClass('ぴ')" @click="toggleSelected('ぴ')"><p class="font-black">ぴ</p>pi</div></td>
              <td><div :class="getClass('ぢ')" @click="toggleSelected('ぢ')"><p class="font-black">ぢ</p>di/ji</div></td>
              <td><div :class="getClass('じ')" @click="toggleSelected('じ')"><p class="font-black">じ</p>ji</div></td>
              <td><div :class="getClass('ぎ')" @click="toggleSelected('ぎ')"><p class="font-black">ぎ</p>gi</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ぶ')" @click="toggleSelected('ぶ')"><p class="font-black">ぶ</p>bu</div></td>
              <td><div :class="getClass('ぷ')" @click="toggleSelected('ぷ')"><p class="font-black">ぷ</p>pu</div></td>
              <td><div :class="getClass('づ')" @click="toggleSelected('づ')"><p class="font-black">づ</p>zu/dzu</div></td>
              <td><div :class="getClass('ず')" @click="toggleSelected('ず')"><p class="font-black">ず</p>zu</div></td>
              <td><div :class="getClass('ぐ')" @click="toggleSelected('ぐ')"><p class="font-black">ぐ</p>gu</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('べ')" @click="toggleSelected('べ')"><p class="font-black">べ</p>be</div></td>
              <td><div :class="getClass('ぺ')" @click="toggleSelected('ぺ')"><p class="font-black">ぺ</p>pe</div></td>
              <td><div :class="getClass('で')" @click="toggleSelected('で')"><p class="font-black">で</p>de</div></td>
              <td><div :class="getClass('ぜ')" @click="toggleSelected('ぜ')"><p class="font-black">ぜ</p>ze</div></td>
              <td><div :class="getClass('げ')" @click="toggleSelected('げ')"><p class="font-black">げ</p>ge</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ぼ')" @click="toggleSelected('ぼ')"><p class="font-black">ぼ</p>bo</div></td>
              <td><div :class="getClass('ぽ')" @click="toggleSelected('ぽ')"><p class="font-black">ぽ</p>po</div></td>
              <td><div :class="getClass('ど')" @click="toggleSelected('ど')"><p class="font-black">ど</p>do</div></td>
              <td><div :class="getClass('ぞ')" @click="toggleSelected('ぞ')"><p class="font-black">ぞ</p>zo</div></td>
              <td><div :class="getClass('ご')" @click="toggleSelected('ご')"><p class="font-black">ご</p>go</div></td>
            </tr>
          </table>
        </div>
      </div>
    </div>



    <div v-if="currentTab === 'katakana'">
      <t-checkbox name="options" v-model="checkboxes.cb29_39.checked" @change="this.toggleGroup(checkboxes.cb29_39)"/> {{$t('kana.basics')}}
      <table class="table-auto w-full">
        <tbody>
        <tr>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb29.checked" @change="this.toggleGroup(checkboxes.cb29)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb30.checked" @change="this.toggleGroup(checkboxes.cb30)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb31.checked" @change="this.toggleGroup(checkboxes.cb31)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb32.checked" @change="this.toggleGroup(checkboxes.cb32)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb33.checked" @change="this.toggleGroup(checkboxes.cb33)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb34.checked" @change="this.toggleGroup(checkboxes.cb34)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb35.checked" @change="this.toggleGroup(checkboxes.cb35)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb36.checked" @change="this.toggleGroup(checkboxes.cb36)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb37.checked" @change="this.toggleGroup(checkboxes.cb37)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb38.checked" @change="this.toggleGroup(checkboxes.cb38)"/></td>
          <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb39.checked" @change="this.toggleGroup(checkboxes.cb39)"/></td>
        </tr>
        <tr>
          <td><div :class="getClass('ア')" @click="toggleSelected('ア')"><p class="font-black">ア</p>a</div></td>
          <td><div :class="getClass('カ')" @click="toggleSelected('カ')"><p class="font-black">カ</p>ka</div></td>
          <td><div :class="getClass('サ')" @click="toggleSelected('サ')"><p class="font-black">サ</p>sa</div></td>
          <td><div :class="getClass('タ')" @click="toggleSelected('タ')"><p class="font-black">タ</p>ta</div></td>
          <td><div :class="getClass('ナ')" @click="toggleSelected('ナ')"><p class="font-black">ナ</p>na</div></td>
          <td><div :class="getClass('ハ')" @click="toggleSelected('ハ')"><p class="font-black">ハ</p>ha</div></td>
          <td><div :class="getClass('マ')" @click="toggleSelected('マ')"><p class="font-black">マ</p>ma</div></td>
          <td><div :class="getClass('ヤ')" @click="toggleSelected('ヤ')"><p class="font-black">ヤ</p>ya</div></td>
          <td><div :class="getClass('ラ')" @click="toggleSelected('ラ')"><p class="font-black">ラ</p>ra</div></td>
          <td><div :class="getClass('ワ')" @click="toggleSelected('ワ')"><p class="font-black">ワ</p>wa</div></td>
          <td><div :class="getClass('ン')" @click="toggleSelected('ン')"><p class="font-black">ン</p>n</div></td>
        </tr>
        <tr>
          <td><div :class="getClass('イ')" @click="toggleSelected('イ')"><p class="font-black">イ</p>i</div></td>
          <td><div :class="getClass('キ')" @click="toggleSelected('キ')"><p class="font-black">キ</p>ki</div></td>
          <td><div :class="getClass('シ')" @click="toggleSelected('シ')"><p class="font-black">シ</p>shi</div></td>
          <td><div :class="getClass('チ')" @click="toggleSelected('チ')"><p class="font-black">チ</p>chi</div></td>
          <td><div :class="getClass('ニ')" @click="toggleSelected('ニ')"><p class="font-black">ニ</p>ni</div></td>
          <td><div :class="getClass('ヒ')" @click="toggleSelected('ヒ')"><p class="font-black">ヒ</p>hi</div></td>
          <td><div :class="getClass('ミ')" @click="toggleSelected('ミ')"><p class="font-black">ミ</p>mi</div></td>
          <td></td>
          <td><div :class="getClass('リ')" @click="toggleSelected('リ')"><p class="font-black">リ</p>ri</div></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><div :class="getClass('ウ')" @click="toggleSelected('ウ')"><p class="font-black">ウ</p>u</div></td>
          <td><div :class="getClass('ク')" @click="toggleSelected('ク')"><p class="font-black">ク</p>ku</div></td>
          <td><div :class="getClass('ス')" @click="toggleSelected('ス')"><p class="font-black">ス</p>su</div></td>
          <td><div :class="getClass('ツ')" @click="toggleSelected('ツ')"><p class="font-black">ツ</p>tsu</div></td>
          <td><div :class="getClass('ヌ')" @click="toggleSelected('ヌ')"><p class="font-black">ヌ</p>nu</div></td>
          <td><div :class="getClass('フ')" @click="toggleSelected('フ')"><p class="font-black">フ</p>fu</div></td>
          <td><div :class="getClass('ム')" @click="toggleSelected('ム')"><p class="font-black">ム</p>mu</div></td>
          <td><div :class="getClass('ユ')" @click="toggleSelected('ユ')"><p class="font-black">ユ</p>yu</div></td>
          <td><div :class="getClass('ル')" @click="toggleSelected('ル')"><p class="font-black">ル</p>ru</div></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><div :class="getClass('エ')" @click="toggleSelected('エ')"><p class="font-black">エ</p>e</div></td>
          <td><div :class="getClass('ケ')" @click="toggleSelected('ケ')"><p class="font-black">ケ</p>ke</div></td>
          <td><div :class="getClass('セ')" @click="toggleSelected('セ')"><p class="font-black">セ</p>se</div></td>
          <td><div :class="getClass('テ')" @click="toggleSelected('テ')"><p class="font-black">テ</p>te</div></td>
          <td><div :class="getClass('ネ')" @click="toggleSelected('ネ')"><p class="font-black">ネ</p>ne</div></td>
          <td><div :class="getClass('ヘ')" @click="toggleSelected('ヘ')"><p class="font-black">ヘ</p>he</div></td>
          <td><div :class="getClass('メ')" @click="toggleSelected('メ')"><p class="font-black">メ</p>me</div></td>
          <td></td>
          <td><div :class="getClass('レ')" @click="toggleSelected('レ')"><p class="font-black">レ</p>re</div></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><div :class="getClass('オ')" @click="toggleSelected('オ')"><p class="font-black">オ</p>o</div></td>
          <td><div :class="getClass('コ')" @click="toggleSelected('コ')"><p class="font-black">コ</p>ko</div></td>
          <td><div :class="getClass('ソ')" @click="toggleSelected('ソ')"><p class="font-black">ソ</p>so</div></td>
          <td><div :class="getClass('ト')" @click="toggleSelected('ト')"><p class="font-black">ト</p>to</div></td>
          <td><div :class="getClass('ノ')" @click="toggleSelected('ノ')"><p class="font-black">ノ</p>no</div></td>
          <td><div :class="getClass('ホ')" @click="toggleSelected('ホ')"><p class="font-black">ホ</p>ho</div></td>
          <td><div :class="getClass('モ')" @click="toggleSelected('モ')"><p class="font-black">モ</p>mo</div></td>
          <td><div :class="getClass('ヨ')" @click="toggleSelected('ヨ')"><p class="font-black">ヨ</p>yo</div></td>
          <td><div :class="getClass('ロ')" @click="toggleSelected('ロ')"><p class="font-black">ロ</p>ro</div></td>
          <td><div :class="getClass('ヲ')" @click="toggleSelected('ヲ')"><p class="font-black">ヲ</p>wo</div></td>
          <td></td>
        </tr>
        </tbody>
      </table>

      <br><br>

      <div class="flex flex-row justify-between space-x-4">
        <div class="w-7/12 bg-blue-50 rounded-lg shadow-xl">
          <t-checkbox name="options" v-model="checkboxes.cb40_51.checked" @change="this.toggleGroup(checkboxes.cb40_51)"/> {{$t('kana.digraphs')}}
          <table class="table-auto w-full">
            <tr>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb40.checked" @change="this.toggleGroup(checkboxes.cb40)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb41.checked" @change="this.toggleGroup(checkboxes.cb41)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb42.checked" @change="this.toggleGroup(checkboxes.cb42)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb43.checked" @change="this.toggleGroup(checkboxes.cb43)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb44.checked" @change="this.toggleGroup(checkboxes.cb44)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb45.checked" @change="this.toggleGroup(checkboxes.cb45)"/></td>
            </tr>
            <tr>
              <td><div :class="getClass('ヂャ')" @click="toggleSelected('ヂャ')"><p class="font-black">ヂャ</p>dya/ja/jya</div></td>
              <td><div :class="getClass('チャ')" @click="toggleSelected('チャ')"><p class="font-black">チャ</p>cha</div></td>
              <td><div :class="getClass('ジャ')" @click="toggleSelected('ジャ')"><p class="font-black">ジャ</p>ja/jya</div></td>
              <td><div :class="getClass('シャ')" @click="toggleSelected('シャ')"><p class="font-black">シャ</p>sha</div></td>
              <td><div :class="getClass('ギャ')" @click="toggleSelected('ギャ')"><p class="font-black">ギャ</p>gya</div></td>
              <td><div :class="getClass('キャ')" @click="toggleSelected('キャ')"><p class="font-black">キャ</p>kya</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ヂュ')" @click="toggleSelected('ヂュ')"><p class="font-black">ヂュ</p>dyu/ju/jyu</div></td>
              <td><div :class="getClass('チュ')" @click="toggleSelected('チュ')"><p class="font-black">チュ</p>chu</div></td>
              <td><div :class="getClass('ジュ')" @click="toggleSelected('ジュ')"><p class="font-black">ジュ</p>ju/jyu</div></td>
              <td><div :class="getClass('シュ')" @click="toggleSelected('シュ')"><p class="font-black">シュ</p>shu</div></td>
              <td><div :class="getClass('ギュ')" @click="toggleSelected('ギュ')"><p class="font-black">ギュ</p>gyu</div></td>
              <td><div :class="getClass('キュ')" @click="toggleSelected('キュ')"><p class="font-black">キュ</p>kyu</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ヂョ')" @click="toggleSelected('ヂョ')"><p class="font-black">ヂョ</p>dyo/jo/jyo</div></td>
              <td><div :class="getClass('チョ')" @click="toggleSelected('チョ')"><p class="font-black">チョ</p>cho</div></td>
              <td><div :class="getClass('ジョ')" @click="toggleSelected('ジョ')"><p class="font-black">ジョ</p>jo/jyo</div></td>
              <td><div :class="getClass('ショ')" @click="toggleSelected('ショ')"><p class="font-black">ショ</p>sho</div></td>
              <td><div :class="getClass('ギョ')" @click="toggleSelected('ギョ')"><p class="font-black">ギョ</p>gyo</div></td>
              <td><div :class="getClass('キョ')" @click="toggleSelected('キョ')"><p class="font-black">キョ</p>kyo</div></td>
            </tr>
          </table>
          <br>
          <table class="table-auto w-full">
            <tr>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb46.checked" @change="this.toggleGroup(checkboxes.cb46)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb47.checked" @change="this.toggleGroup(checkboxes.cb47)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb48.checked" @change="this.toggleGroup(checkboxes.cb48)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb49.checked" @change="this.toggleGroup(checkboxes.cb49)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb50.checked" @change="this.toggleGroup(checkboxes.cb50)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb51.checked" @change="this.toggleGroup(checkboxes.cb51)"/></td>
            </tr>
            <tr>
              <td><div :class="getClass('リャ')" @click="toggleSelected('リャ')"><p class="font-black">リャ</p>rya</div></td>
              <td><div :class="getClass('ミャ')" @click="toggleSelected('ミャ')"><p class="font-black">ミャ</p>mya</div></td>
              <td><div :class="getClass('ピャ')" @click="toggleSelected('ピャ')"><p class="font-black">ピャ</p>pya</div></td>
              <td><div :class="getClass('ビャ')" @click="toggleSelected('ビャ')"><p class="font-black">ビャ</p>bya</div></td>
              <td><div :class="getClass('ヒャ')" @click="toggleSelected('ヒャ')"><p class="font-black">ヒャ</p>hya</div></td>
              <td><div :class="getClass('ニャ')" @click="toggleSelected('ニャ')"><p class="font-black">ニャ</p>nya</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('リュ')" @click="toggleSelected('リュ')"><p class="font-black">リュ</p>ryu</div></td>
              <td><div :class="getClass('ミュ')" @click="toggleSelected('ミュ')"><p class="font-black">ミュ</p>myu</div></td>
              <td><div :class="getClass('ピュ')" @click="toggleSelected('ピュ')"><p class="font-black">ピュ</p>pyu</div></td>
              <td><div :class="getClass('ビュ')" @click="toggleSelected('ビュ')"><p class="font-black">ビュ</p>byu</div></td>
              <td><div :class="getClass('ヒュ')" @click="toggleSelected('ヒュ')"><p class="font-black">ヒュ</p>hyu</div></td>
              <td><div :class="getClass('ニュ')" @click="toggleSelected('ニュ')"><p class="font-black">ニュ</p>nyu</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('リョ')" @click="toggleSelected('リョ')"><p class="font-black">リョ</p>ryo</div></td>
              <td><div :class="getClass('ミョ')" @click="toggleSelected('ミョ')"><p class="font-black">ミョ</p>myo</div></td>
              <td><div :class="getClass('ピョ')" @click="toggleSelected('ピョ')"><p class="font-black">ピョ</p>pyo</div></td>
              <td><div :class="getClass('ビョ')" @click="toggleSelected('ビョ')"><p class="font-black">ビョ</p>byo</div></td>
              <td><div :class="getClass('ヒョ')" @click="toggleSelected('ヒョ')"><p class="font-black">ヒョ</p>hyo</div></td>
              <td><div :class="getClass('ニョ')" @click="toggleSelected('ニョ')"><p class="font-black">ニョ</p>nyo</div></td>
            </tr>
          </table>
        </div>
        <div class="w-6/12">
          <t-checkbox name="options" v-model="checkboxes.cb52_56.checked" @change="this.toggleGroup(checkboxes.cb52_56)"/> {{$t('kana.diacritics')}}
          <table class="table-auto w-full">
            <tr>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb52.checked" @change="this.toggleGroup(checkboxes.cb52)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb53.checked" @change="this.toggleGroup(checkboxes.cb53)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb54.checked" @change="this.toggleGroup(checkboxes.cb54)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb55.checked" @change="this.toggleGroup(checkboxes.cb55)"/></td>
              <td class="text-center"><t-checkbox name="options" v-model="checkboxes.cb56.checked" @change="this.toggleGroup(checkboxes.cb56)"/></td>
            </tr>
            <tr>
              <td><div :class="getClass('バ')" @click="toggleSelected('バ')"><p class="font-black">バ</p>ba</div></td>
              <td><div :class="getClass('パ')" @click="toggleSelected('パ')"><p class="font-black">パ</p>pa</div></td>
              <td><div :class="getClass('ダ')" @click="toggleSelected('ダ')"><p class="font-black">ダ</p>da</div></td>
              <td><div :class="getClass('ザ')" @click="toggleSelected('ザ')"><p class="font-black">ザ</p>za</div></td>
              <td><div :class="getClass('ガ')" @click="toggleSelected('ガ')"><p class="font-black">ガ</p>ga</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ビ')" @click="toggleSelected('ビ')"><p class="font-black">ビ</p>bi</div></td>
              <td><div :class="getClass('ピ')" @click="toggleSelected('ピ')"><p class="font-black">ピ</p>pi</div></td>
              <td><div :class="getClass('ヂ')" @click="toggleSelected('ヂ')"><p class="font-black">ヂ</p>di/ji</div></td>
              <td><div :class="getClass('ジ')" @click="toggleSelected('ジ')"><p class="font-black">ジ</p>ji</div></td>
              <td><div :class="getClass('ギ')" @click="toggleSelected('ギ')"><p class="font-black">ギ</p>gi</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ブ')" @click="toggleSelected('ブ')"><p class="font-black">ブ</p>bu</div></td>
              <td><div :class="getClass('プ')" @click="toggleSelected('プ')"><p class="font-black">プ</p>pu</div></td>
              <td><div :class="getClass('ヅ')" @click="toggleSelected('ヅ')"><p class="font-black">ヅ</p>zu/dzu</div></td>
              <td><div :class="getClass('ズ')" @click="toggleSelected('ズ')"><p class="font-black">ズ</p>zu</div></td>
              <td><div :class="getClass('グ')" @click="toggleSelected('グ')"><p class="font-black">グ</p>gu</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ベ')" @click="toggleSelected('ベ')"><p class="font-black">ベ</p>be</div></td>
              <td><div :class="getClass('ペ')" @click="toggleSelected('ペ')"><p class="font-black">ペ</p>pe</div></td>
              <td><div :class="getClass('デ')" @click="toggleSelected('デ')"><p class="font-black">デ</p>de</div></td>
              <td><div :class="getClass('ゼ')" @click="toggleSelected('ゼ')"><p class="font-black">ゼ</p>ze</div></td>
              <td><div :class="getClass('ゲ')" @click="toggleSelected('ゲ')"><p class="font-black">ゲ</p>ge</div></td>
            </tr>
            <tr>
              <td><div :class="getClass('ボ')" @click="toggleSelected('ボ')"><p class="font-black">ボ</p>bo</div></td>
              <td><div :class="getClass('ポ')" @click="toggleSelected('ポ')"><p class="font-black">ポ</p>po</div></td>
              <td><div :class="getClass('ド')" @click="toggleSelected('ド')"><p class="font-black">ド</p>do</div></td>
              <td><div :class="getClass('ゾ')" @click="toggleSelected('ゾ')"><p class="font-black">ゾ</p>zo</div></td>
              <td><div :class="getClass('ゴ')" @click="toggleSelected('ゴ')"><p class="font-black">ゴ</p>go</div></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TCheckbox} from '@variantjs/vue'

export default {
  name: "KanaTable",
  components: {TCheckbox},
  props: {
    kana: {
      flashcards: []
    }
  },
  data: function () {
    return {
      currentTab: 'hiragana',
      checkboxes: {
        cb1_11: {questions: [
            'あ', 'い', 'う', 'え', 'お',
            'か', 'き', 'く', 'け', 'こ',
            'さ', 'し', 'す', 'せ', 'そ',
            'た', 'ち', 'つ', 'て', 'と',
            'な', 'に', 'ぬ', 'ね', 'の',
            'は', 'ひ', 'ふ', 'へ', 'ほ',
            'ま', 'み', 'む', 'め', 'も',
            'や', 'ゆ', 'よ',
            'ら', 'り', 'る', 'れ', 'ろ',
            'わ', 'を',
            'ん'
          ], checked: true},
        cb1: {questions: ['あ', 'い', 'う', 'え', 'お'], checked: true},
        cb2: {questions: ['か', 'き', 'く', 'け', 'こ'], checked: true},
        cb3: {questions: ['さ', 'し', 'す', 'せ', 'そ'], checked: true},
        cb4: {questions: ['た', 'ち', 'つ', 'て', 'と'], checked: true},
        cb5: {questions: ['な', 'に', 'ぬ', 'ね', 'の'], checked: true},
        cb6: {questions: ['は', 'ひ', 'ふ', 'へ', 'ほ'], checked: true},
        cb7: {questions: ['ま', 'み', 'む', 'め', 'も'], checked: true},
        cb8: {questions: ['や', 'ゆ', 'よ'], checked: true},
        cb9: {questions: ['ら', 'り', 'る', 'れ', 'ろ'], checked: true},
        cb10: {questions: ['わ', 'を'], checked: true},
        cb11: {questions: ['ん'], checked: true},

        cb12_23: {questions: [
            'ぢゃ', 'ぢゅ', 'ぢょ',
            'ちゃ', 'ちゅ', 'ちょ' ,
            'じゃ', 'じゅ', 'じょ',
            'しゃ', 'しゅ', 'しょ',
            'ぎゃ', 'ぎゅ', 'ぎょ',
            'きゃ', 'きゅ', 'きょ',
            'りゃ', 'りゅ', 'りょ',
            'みゃ', 'みゅ', 'みょ',
            'ぴゃ', 'ぴゅ', 'ぴょ',
            'びゃ', 'びゅ', 'びょ',
            'ひゃ', 'ひゅ', 'ひょ',
            'にゃ', 'にゅ', 'にょ'
          ], checked: true},
        cb12: {questions: ['ぢゃ', 'ぢゅ', 'ぢょ'], checked: true},
        cb13: {questions: ['ちゃ', 'ちゅ', 'ちょ'], checked: true},
        cb14: {questions: ['じゃ', 'じゅ', 'じょ'], checked: true},
        cb15: {questions: ['しゃ', 'しゅ', 'しょ'], checked: true},
        cb16: {questions: ['ぎゃ', 'ぎゅ', 'ぎょ'], checked: true},
        cb17: {questions: ['きゃ', 'きゅ', 'きょ'], checked: true},

        cb18: {questions: ['りゃ', 'りゅ', 'りょ'], checked: true},
        cb19: {questions: ['みゃ', 'みゅ', 'みょ'], checked: true},
        cb20: {questions: ['ぴゃ', 'ぴゅ', 'ぴょ'], checked: true},
        cb21: {questions: ['びゃ', 'びゅ', 'びょ'], checked: true},
        cb22: {questions: ['ひゃ', 'ひゅ', 'ひょ'], checked: true},
        cb23: {questions: ['にゃ', 'にゅ', 'にょ'], checked: true},

        cb24_28: {questions: [
            'ば', 'び', 'ぶ', 'べ', 'ぼ',
            'ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ',
            'だ', 'ぢ', 'づ', 'で', 'ど',
            'ざ', 'じ', 'ず', 'ぜ', 'ぞ',
            'が', 'ぎ', 'ぐ', 'げ', 'ご'
          ], checked: true},
        cb24: {questions: ['ば', 'び', 'ぶ', 'べ', 'ぼ'], checked: true},
        cb25: {questions: ['ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ'], checked: true},
        cb26: {questions: ['だ', 'ぢ', 'づ', 'で', 'ど'], checked: true},
        cb27: {questions: ['ざ', 'じ', 'ず', 'ぜ', 'ぞ'], checked: true},
        cb28: {questions: ['が', 'ぎ', 'ぐ', 'げ', 'ご'], checked: true},


        cb29_39: {questions: [
            'ア', 'イ', 'ウ', 'エ', 'オ',
            'カ', 'キ', 'ク', 'ケ', 'コ',
            'サ', 'シ', 'ス', 'セ', 'ソ',
            'タ', 'チ', 'ツ', 'テ', 'ト',
            'ナ', 'ニ', 'ヌ', 'ネ', 'ノ',
            'ハ', 'ヒ', 'フ', 'ヘ', 'ホ',
            'マ', 'ミ', 'ム', 'メ', 'モ',
            'ヤ', 'ユ', 'ヨ',
            'ラ', 'リ', 'ル', 'レ', 'ロ',
            'ワ', 'ヲ',
            'ン'
          ], checked: true},
        cb29: {questions: ['ア', 'イ', 'ウ', 'エ', 'オ'], checked: true},
        cb30: {questions: ['カ', 'キ', 'ク', 'ケ', 'コ'], checked: true},
        cb31: {questions: ['サ', 'シ', 'ス', 'セ', 'ソ'], checked: true},
        cb32: {questions: ['タ', 'チ', 'ツ', 'テ', 'ト'], checked: true},
        cb33: {questions: ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'], checked: true},
        cb34: {questions: ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'], checked: true},
        cb35: {questions: ['マ', 'ミ', 'ム', 'メ', 'モ'], checked: true},
        cb36: {questions: ['ヤ', 'ユ', 'ヨ'], checked: true},
        cb37: {questions: ['ラ', 'リ', 'ル', 'レ', 'ロ'], checked: true},
        cb38: {questions: ['ワ', 'ヲ'], checked: true},
        cb39: {questions: ['ン'], checked: true},

        cb40_51: {questions: [
            'ヂャ', 'ヂュ', 'ヂョ',
            'チャ', 'チュ', 'チョ',
            'ジャ', 'ジュ', 'ジョ',
            'シャ', 'シュ', 'ショ',
            'ギャ', 'ギュ', 'ギョ',
            'キャ', 'キュ', 'キョ',
            'リャ', 'リュ', 'リョ',
            'ミャ', 'ミュ', 'ミョ',
            'ピャ', 'ピュ', 'ピョ',
            'ビャ', 'ビュ', 'ビョ',
            'ヒャ', 'ヒュ', 'ヒョ',
            'ニャ', 'ニュ', 'ニョ',
          ], checked: true},
        cb40: {questions: ['ヂャ', 'ヂュ', 'ヂョ'], checked: true},
        cb41: {questions: ['チャ', 'チュ', 'チョ'], checked: true},
        cb42: {questions: ['ジャ', 'ジュ', 'ジョ'], checked: true},
        cb43: {questions: ['シャ', 'シュ', 'ショ'], checked: true},
        cb44: {questions: ['ギャ', 'ギュ', 'ギョ'], checked: true},
        cb45: {questions: ['キャ', 'キュ', 'キョ'], checked: true},

        cb46: {questions: ['リャ', 'リュ', 'リョ'], checked: true},
        cb47: {questions: ['ミャ', 'ミュ', 'ミョ'], checked: true},
        cb48: {questions: ['ピャ', 'ピュ', 'ピョ'], checked: true},
        cb49: {questions: ['ビャ', 'ビュ', 'ビョ'], checked: true},
        cb50: {questions: ['ヒャ', 'ヒュ', 'ヒョ'], checked: true},
        cb51: {questions: ['ニャ', 'ニュ', 'ニョ'], checked: true},

        cb52_56: {questions: [
            'バ', 'ビ', 'ブ', 'ベ', 'ボ',
            'パ', 'ピ', 'プ', 'ペ', 'ポ',
            'ダ', 'ヂ', 'ヅ', 'デ', 'ド',
            'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ',
            'ガ', 'ギ', 'グ', 'ゲ', 'ゴ'
          ], checked: true},
        cb52: {questions: ['バ', 'ビ', 'ブ', 'ベ', 'ボ'], checked: true},
        cb53: {questions: ['パ', 'ピ', 'プ', 'ペ', 'ポ'], checked: true},
        cb54: {questions: ['ダ', 'ヂ', 'ヅ', 'デ', 'ド'], checked: true},
        cb55: {questions: ['ザ', 'ジ', 'ズ', 'ゼ', 'ゾ'], checked: true},
        cb56: {questions: ['ガ', 'ギ', 'グ', 'ゲ', 'ゴ'], checked: true}

      }
    }
  },
  mounted() {
    for (let checkboxesKey in this.checkboxes) {
      for (let question of this.checkboxes[checkboxesKey].questions) {
        if (!this.kana.flashcards.find(k => k.question === question).selected) {
          this.checkboxes[checkboxesKey].checked = false;
        }
      }
    }
  },
  methods: {
    getSelected: function (question) {
      for (const kanaElement of this.kana.flashcards) {
        if (kanaElement.question === question) {
          return kanaElement.selected;
        }
      }
      return false;
    },
    getClass: function (question) {
      if (this.kana.flashcards.find(k => k.question === question).selected) {
        return "shadow-xl p-1 m-1 bg-blue-200 rounded-lg text-center font-thin cursor-pointer";
      }
      return "shadow-xl p-1 m-1 bg-gray-100 rounded-lg text-center font-thin cursor-pointer";
    },
    toggleSelected: function (question) {
      const k = this.kana.flashcards.find(k => k.question === question);
      k.selected = !k.selected;

      for (let checkboxesKey in this.checkboxes) {
        const checkbox = this.checkboxes[checkboxesKey];
        if (checkbox.questions.includes(question)) {
          if (!k.selected) {
            checkbox.checked = false;
          } else {
            checkbox.checked = checkbox.questions.every(q => this.kana.flashcards.find(k => k.question === q).selected);
          }
        }
      }
    },
    toggleGroup: function (checkbox) {
      const checked = checkbox.checked;
      for (const question of checkbox.questions) {
        let flashcard = this.kana.flashcards.find(k => k.question === question);
        if (flashcard.selected !== checked) {
          this.toggleSelected(flashcard.question);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
